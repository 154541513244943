module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HYQ750DTLE"],"gtagConfig":{},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../plugins/rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"f8e5ccf690aa4813aa5e188a04f94bf9","captureUncaught":true,"captureUnhandledRejections":true},
    }]
